import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import useSound from 'use-sound'
import soundWebm from '../common/assets/voiceover.webm'
import soundMp3 from '../common/assets/voiceover.mp3'
import soundJson from '../common/assets/voiceover.json'

/**
* play function
* @param {string | {id: string}} sprite sprite name oder object with id
* @returns {number} play id
*/
const noopPlay = (sprite) => {}
/**
* stop playing
* @param {null | string | {id: string}} sprite sprite name oder object with id
*/
const noopStop = (sprite) => {}

const VoiceoverContext = React.createContext([noopPlay, noopStop])
VoiceoverContext.displayName = 'VoiceoverContext'

export const useVoiceover = () => React.useContext(VoiceoverContext)
export default VoiceoverContext

export const VoiceoverProvider = ({ children }) => {
    const howlerRef = useRef(null)

    const [, { sound }] = useSound([soundWebm, soundMp3], {
        preload: true,
        sprite: soundJson.sprite,
        interrupt: true,
    })

    howlerRef.current = sound

    useEffect(() => {
        return () => {
            /**
            * Stop, unload and destroy howler object
            */
            howlerRef.current?.off() // Remove event listener
            howlerRef.current?.stop() // Stop playback
            howlerRef.current?.unload() // Remove sound from pool
            howlerRef.current = null
        }
    }, [])

    const playVoiceover = useCallback((sprite) => {
        if (howlerRef.current) {
            howlerRef.current.stop()
            return howlerRef.current.play(sprite?.id ?? sprite)
        }
        return 0
    }, [])

    const stopVoiceover = useCallback((sprite) => {
        if (howlerRef.current) {
            return howlerRef.current.stop(sprite?.id ?? sprite)
        }
        return 0
    }, [])

    const value = useMemo(() => [playVoiceover, stopVoiceover, sound != null], [playVoiceover, stopVoiceover, sound])

    return (
        <VoiceoverContext.Provider value={value}>
            {children}
        </VoiceoverContext.Provider>
    )
}
