import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import { HeadLine } from '../common/text'
import { Views, SPRITES } from '../constants'
import withDelay from '../hoc/withDelay/withDelay'
import { useTimeline, useSoundContext } from '../hooks'
import { vp } from '../common/layout'

const StyledHeadLine = styled(HeadLine)`
    margin-bottom: 2rem;
    opacity: 0;
    max-width: 70%;
    ${vp.mobileCss(`
        font-size: 1rem;
        margin: 0 1rem 3rem 1rem;
        max-width: 90%;
    `)}
     ${vp.medium(`
       font-size: 1.4rem;
        margin: 0 1rem 3rem 1rem;
        max-width: 80%;
    `)}
`

function AnniversaryView() {
    const history = useHistory()
    const timeline = useTimeline()
    const { t } = useTranslation()
    const headline = useRef()
    const soundId = useRef()
    const q = gsap.utils.selector(headline)
    const [, { sound }] = useSoundContext()

    useEffect(() => {
        if (sound) {
            timeline.call(() => {
                soundId.current = sound.play(SPRITES.anniversary.id)
            })
            timeline.wait(2)
            timeline.to(q('.text'), 0.8, {
                opacity: 1,
                ease: 'power1',
            })
            timeline.wait(11)
            timeline.call(() => {
                history.push(Views.EndView.path)
                sound.play(SPRITES.anniversary_whoosh_out.id)
            })

            timeline.play()
        }

        return () => {
            if (soundId.current) {
                sound.stop(soundId.current)
            }
        }
    }, [sound])

    return (
        <div ref={headline} className="flex justify-center items-end h-full"><StyledHeadLine className="text">{t('anniversary.headline')}</StyledHeadLine></div>
    )
}

export default withDelay(AnniversaryView, Views.AnniversaryView.showInDelay)
